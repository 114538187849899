import { GET_NUTRITION_SUMMARY_RESPONSE } from 'actions/baskets';

const initialState = {
  averageCalories: 0,
  averageProtein: 0,
  averageNetCarbs: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NUTRITION_SUMMARY_RESPONSE:
      return {
        ...state,
        ...action.response.nutritionSummary,
      };
    default:
      return state;
  }
};
