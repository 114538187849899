import { makeApiRequest } from 'actions/api';
import { getOptIns } from 'actions/optIns';
import { getUser } from 'actions/user';
import RequestService from 'services/RequestService';

export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const CREATE_ADDRESS_REQUEST = 'CREATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_RESPONSE = 'CREATE_ADDRESS_RESPONSE';
export const CREATE_ADDRESS_ERROR = 'CREATE_ADDRESS_ERROR';

export const DESTROY_ADDRESS = 'DESTROY_ADDRESS';
export const DESTROY_ADDRESS_REQUEST = 'DESTROY_ADDRESS_REQUEST';
export const DESTROY_ADDRESS_RESPONSE = 'DESTROY_ADDRESS_RESPONSE';
export const DESTROY_ADDRESS_ERROR = 'DESTROY_ADDRESS_ERROR';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_RESPONSE = 'UPDATE_ADDRESS_RESPONSE';
export const UPDATE_ADDRESS_ERROR = 'UPDATE_ADDRESS_ERROR';

export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const SET_DEFAULT_ADDRESS_REQUEST = 'SET_DEFAULT_ADDRESS_REQUEST';
export const SET_DEFAULT_ADDRESS_RESPONSE = 'SET_DEFAULT_ADDRESS_RESPONSE';
export const SET_DEFAULT_ADDRESS_ERROR = 'SET_DEFAULT_ADDRESS_ERROR';

export const createAlternateAddressAction = ({ address }) =>
  makeApiRequest({
    requestType: CREATE_ADDRESS,
    method: RequestService.createAddress,
    params: { address },
  });

export const destroyAlternateAddressAction = id =>
  makeApiRequest({
    requestType: DESTROY_ADDRESS,
    method: RequestService.destroyAddress,
    params: { id },
  });

export const updateAddress = ({ address }) => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: UPDATE_ADDRESS,
        method: RequestService.updateAddress,
        params: { address },
      })
    );
    if (response.ok) {
      dispatch(getOptIns());
    }
    return response;
  };
};

export const setDefaultAddressAction = id => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: SET_DEFAULT_ADDRESS,
        method: RequestService.setDefaultAddress,
        params: { id },
      })
    );
    if (response.ok) {
      dispatch(getOptIns());
    }
    return response;
  };
};

const addressActionWrapper = action => arg => async dispatch => {
  const response = await dispatch(action(arg));

  if (!response.ok) {
    dispatch(getUser());
  }
  return response;
};

export const setDefaultAddress = addressActionWrapper(setDefaultAddressAction);
export const destroyAlternateAddress = addressActionWrapper(destroyAlternateAddressAction);
export const createAlternateAddress = addressActionWrapper(createAlternateAddressAction);
