import { GET_OFFER_REWARDS_RESPONSE, GET_VOUCHER_DETAILS_RESPONSE } from 'actions/offerRewards';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_REWARDS_RESPONSE:
      return {
        ...state,
        ...action.response.offerRewards,
      };
    case GET_VOUCHER_DETAILS_RESPONSE:
      return {
        ...state,
        basketVoucherDetails: {
          ...state.basketVoucherDetails,
          ...action.response.basketVoucherDetails,
        },
      };
    default:
      return state;
  }
};

export const selectWeeklyBasket = state => state.weeklyBasket;
export const selectCurrentMenuSlug = state => state.currentMenuSlug;
