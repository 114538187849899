import * as Amplitude from '@amplitude/analytics-browser';
// eslint-disable-next-line no-restricted-imports
import { isMobile, isTablet } from 'react-device-detect';
import { getItem } from 'helpers/localStorage';

import {
  CREATE_ADDRESS_RESPONSE,
  DESTROY_ADDRESS_RESPONSE,
  UPDATE_ADDRESS_RESPONSE,
  SET_DEFAULT_ADDRESS_RESPONSE,
} from 'actions/addresses';
import {
  ADD_MEAL,
  REMOVE_MEAL,
  UNDO_BASKET_EDITS,
  CHANGE_MEAL_OPTION,
  UPDATE_MEAL_SERVINGS,
} from 'actions/basketEdits';
import {
  ADD_BASKET_RESPONSE,
  UPDATE_BASKET_RESPONSE,
  UNDONATE_BASKET_RESPONSE,
  DONATE_BASKET_RESPONSE,
  SKIP_BASKET_RESPONSE,
  UNSKIP_BASKET_RESPONSE,
  UPDATE_BASKET_ADDRESS_RESPONSE,
} from 'actions/baskets';
import { ADD_FAVORITE_RESPONSE, REMOVE_FAVORITE_RESPONSE } from 'actions/favorites';
import {
  UPDATE_MEAL_FILTER,
  DASHBOARD_CLEAR_ALL_FILTERS,
  CLEAR_MEAL_FILTER,
} from 'actions/mealFilters';
import { GET_MEALS_REQUEST } from 'actions/meals';
import { MESSAGE_INTERACTION } from 'actions/messageCenter';
import { ADD_REVIEW_RESPONSE, ADD_REVIEW_FOR_COOKBOOK_RESPONSE } from 'actions/reviews';
import { ROUTE_CHANGE } from 'actions/routes';
import { SET_FAVORITE } from 'actions/searchParams';
import { UPDATE_TASTE_PROFILE_RESPONSE } from 'actions/tasteProfile';
import {
  UPDATE_USER_RESPONSE,
  UNPAUSE_USER_RESPONSE,
  PAUSE_USER_REQUEST,
  SEND_INVITATION_RESPONSE,
  UPDATE_PAYMENT_RESPONSE,
  UPDATE_PASSWORD_RESPONSE,
  UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE,
  UPDATE_OPT_IN_SELECTIONS_RESPONSE,
} from 'actions/user';
import { getEnvConstants } from 'helpers/envConstants';
import * as Routes from 'helpers/routes';

export const ANALYTICS_EVENT = 'ANALYTICS_EVENT';
export const SKIP_FROM_ORDER_SUMMARY_CTA = 'skip.button.1';
export const SKIP_FROM_EDIT_BASKET_CTA = 'skip.button.2';
export const SKIP_FROM_UPCOMING_ORDERS_CTA = 'skip.button.3';
export const SKIP_FROM_MANAGE_DELIVERY_SLIDEOUT_CTA = 'manage_delivery_slideout';
export const DONATE_FROM_ORDER_SUMMARY_CTA = 'donate.button.1';
export const DONATE_FROM_EDIT_BASKET_CTA = 'donate.button.2';
export const DONATE_FROM_UPCOMING_ORDERS_CTA = 'donate.button.3';
export const DONATE_FROM_SKIPPED_UPCOMING_ORDERS_CTA = 'donate.button.4';
export const UNSKIP_FROM_BASKET_SUMMARY_CTA = 'unskip.button.1';
export const UNSKIP_FROM_EDIT_BASKET_CTA = 'unskip.button.2';
export const UNSKIP_FROM_MEAL_DETAILS_CTA = 'unskip.button.3';
export const UNDONATE_FROM_BASKET_SUMMARY_CTA = 'undonate.button.1';
export const UNDONATE_FROM_EDIT_BASKET_CTA = 'undonate.button.2';

export const UNPAUSE_FROM_UNSKIP_BASKET_MODAL = 'edit.modal.unskip_reactivate_account.clicked';
export const UNPAUSE_FROM_UNSKIP_DASHBOARD_MODAL =
  'dashboard.modal.unskip_reactivate_account.clicked';
export const UNPAUSE_FROM_ACCOUNT_SETTINGS = 'account.reactivate_account.clicked';
export const UNPAUSE_FROM_BANNER = 'banner.reactivate_account.clicked';

export const ONBOARDING_MODAL_SHOW = 'ONBOARDING_MODAL_SHOW';
export const ONBOARDING_MODAL_DISMISS = 'ONBOARDING_MODAL_DISMISS';
export const ONBOARDING_MODAL_NEXT = 'ONBOARDING_MODAL_NEXT';
export const ONBOARDING_MODAL_PREVIOUS = 'ONBOARDING_MODAL_PREVIOUS';

export const CUSTOMER_FAVORITE_MEAL = 'CUSTOMER_FAVORITE_MEAL';
export const SEARCH_HOLIDAY_BUNDLE_STORES = 'SEARCH_HOLIDAY_BUNDLE_STORES';

export const COPY_MEAL_LINK_TO_SHARE = 'COPY_MEAL_LINK_TO_SHARE';
export const SHARE_MEAL_TO_PINTEREST = 'SHARE_MEAL_TO_PINTEREST';
export const SHARE_MEAL_TO_EMAIL = 'SHARE_MEAL_TO_EMAIL';
export const DASHBOARD_REFERRAL_SHARE = 'DASHBOARD_REFERRAL_SHARE';
export const DASHBOARD_REFERRAL_VIEW_DETAILS = 'DASHBOARD_REFERRAL_VIEW_DETAILS';

let _initialized = false;

export const init = () => {
  const { AMPLITUDE_API_KEY } = getEnvConstants();
  if (!AMPLITUDE_API_KEY) return;

  Amplitude.init(AMPLITUDE_API_KEY, {
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
      elementInteractions: true,
    },
    logLevel: Amplitude.Types.LogLevel.Warn,
  });
  _initialized = true;
};

export const isInitialized = () => _initialized;
export const setUserId = id => Amplitude.setUserId(id);
export const setUserProperties = props => {
  const identify = new Amplitude.Identify();
  Object.entries(props).forEach(([key, value]) => {
    identify.set(key, value);
  });
  Amplitude.identify(identify);
};
export const clearUser = () => {
  Amplitude.reset();
  _initialized = false;
};

const logUserType = () => {
  let userType;
  if (isMobile) {
    userType = 'mobileUser';
  } else if (isTablet) {
    userType = 'tabletUser';
  } else {
    userType = 'desktopUser';
  }

  let isOptimized = false;

  try {
    const yottaaString = getItem('rapid.testVariations');
    const yottaaObject = JSON.parse(yottaaString);
    if (yottaaObject.length > 0) {
      isOptimized = yottaaObject[0].variationName === 'Optimized';
    }
  } catch {
    // totally fine
  }

  const identify = new Amplitude.Identify();
  identify.set(userType, true);
  identify.set('isOptimized', isOptimized);
  Amplitude.identify(identify);
};

export const track = ({ type, metadata, pathname, ...payload }, properties = {}) => {
  const eventPathname = pathname || window.location.pathname;
  const eventName = getEventName(type, eventPathname, payload);
  if (!eventName) return;
  const allProperties = { ...properties, ...metadata };
  if (!isInitialized()) init();
  logUserType();
  Amplitude.track(eventName, allProperties);
};

const transformExperiments = user => {
  if (user.experimentVariants && user.experimentVariants.length > 0) {
    const experiments = user.experimentVariants.reduce(
      (obj, experiment) => ({
        ...obj,
        [experiment.experimentName]: experiment.variant,
      }),
      {}
    );
    return experiments;
  }
};

export const setAmplitudeUserProperties = user => {
  setUserId(user.uuid);
  setUserProperties({
    email: user.email,
    experiments: transformExperiments(user),
    mealPlan: user.mealPlanSlug,
  });
};

export const getEventName = (type, pathname, actionPayload) => {
  switch (type) {
    case UPDATE_USER_RESPONSE:
      if (pathname === Routes.ACCOUNT_EDIT_PATH) {
        return 'account.contact_info.submitted';
      }
      if (pathname === Routes.ACCOUNT_SUBSCRIPTION_PATH) {
        return 'account.subscription.submitted';
      }
      break;
    case UNPAUSE_USER_RESPONSE:
      return 'dashboard.unpause';
    case PAUSE_USER_REQUEST:
      return 'account.one_touch_pause.clicked';
    case UPDATE_PAYMENT_RESPONSE:
      return 'account.payment.submitted';
    case UPDATE_PASSWORD_RESPONSE:
      return 'account.password.submitted';
    case UPDATE_TASTE_PROFILE_RESPONSE:
      return 'account.taste_profile.submitted';
    case UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE:
      return 'account.addon_selections.submitted';
    case UPDATE_OPT_IN_SELECTIONS_RESPONSE:
      return 'account.opt_in_selections.submitted';
    case ADD_REVIEW_FOR_COOKBOOK_RESPONSE:
      return 'cookbook.review.submitted';
    case ADD_REVIEW_RESPONSE:
      return 'dashboard.review.submitted';
    case SKIP_BASKET_RESPONSE:
      return 'dashboard.skip.clicked';
    case DONATE_BASKET_RESPONSE:
      return 'dashboard.donate.clicked';
    case UNSKIP_BASKET_RESPONSE:
      return 'dashboard.unskip.clicked';
    case UNDONATE_BASKET_RESPONSE:
      return 'dashboard.undonate.clicked';
    case ADD_BASKET_RESPONSE:
    case UPDATE_BASKET_RESPONSE: {
      if (Routes.isSummaryPath(pathname, actionPayload.response.weeklyBasket.menu.slug))
        return 'dashboard.order_summary.submitted';
      return 'dashboard.basket_edit.submitted';
    }
    case UPDATE_BASKET_ADDRESS_RESPONSE:
      return 'basket.address.submitted';
    case SET_DEFAULT_ADDRESS_RESPONSE:
      return 'account.default_alternate_address.submitted';
    case UPDATE_ADDRESS_RESPONSE:
      return 'account.address_update.submitted';
    case DESTROY_ADDRESS_RESPONSE:
      return 'account.alternate_address.deleted';
    case CREATE_ADDRESS_RESPONSE:
      if (pathname === Routes.ACCOUNT_EDIT_PATH && actionPayload.params.address.isDefault) {
        return 'account.default_address.submitted';
      } else if (pathname === Routes.ACCOUNT_EDIT_PATH) {
        return 'account.alternate_address.submitted';
      } else {
        return 'basket.alternate_address.submitted';
      }
    case GET_MEALS_REQUEST:
      return 'my_cookbook.searched';
    case ADD_MEAL:
      return 'dashboard.add.meal';
    case REMOVE_MEAL:
      return 'dashboard.remove.meal';
    case UPDATE_MEAL_SERVINGS:
      return 'dashboard.update.servings';
    case UNDO_BASKET_EDITS:
      return 'dashboard.save.cancelled';
    case CHANGE_MEAL_OPTION:
      return 'dashboard.customize.meal';
    case ONBOARDING_MODAL_SHOW:
      return 'onboarding.clicked';
    case ONBOARDING_MODAL_DISMISS:
      return 'onboarding.dismiss.clicked';
    case ONBOARDING_MODAL_NEXT:
      return 'onboarding.next.clicked';
    case ONBOARDING_MODAL_PREVIOUS:
      return 'onboarding.previous.clicked';
    case SEND_INVITATION_RESPONSE:
      return 'dashboard.referral.sent';
    case ANALYTICS_EVENT:
      return actionPayload.eventName;
    case MESSAGE_INTERACTION:
      return actionPayload.message.amplitudeEventName;
    case ADD_FAVORITE_RESPONSE:
      return 'cookbook.favorite.added';
    case REMOVE_FAVORITE_RESPONSE:
      return 'cookbook.favorite.removed';
    case SET_FAVORITE:
      return 'cookbook.favorites_filter.clicked';
    case DASHBOARD_CLEAR_ALL_FILTERS:
      return 'filters.cleared';
    case CLEAR_MEAL_FILTER:
      return `filters.${actionPayload.filter}.cleared`;
    case UPDATE_MEAL_FILTER:
      return `filters.${actionPayload.filter}.updated`;
    case CUSTOMER_FAVORITE_MEAL:
      return 'cookbook.cf_meal.clicked';
    case SEARCH_HOLIDAY_BUNDLE_STORES:
      return 'holiday_lander.searched';
    case COPY_MEAL_LINK_TO_SHARE:
      return 'meal_details.copy_meal_link.clicked';
    case SHARE_MEAL_TO_PINTEREST:
      return 'meal_details.share_to_pinterest.clicked';
    case SHARE_MEAL_TO_EMAIL:
      return 'meal_details.share_to_email.clicked';
    case ROUTE_CHANGE: {
      if (actionPayload.routeName === Routes.MEAL_DETAILS_PATH_NAME)
        return 'dashboard.meal_recipe.viewed';
      if (actionPayload.routeName === Routes.MENU_PATH_NAME) return 'dashboard.weekly_menu.viewed';
      if (actionPayload.routeName === Routes.SUMMARY_PATH_NAME) return 'dashboard.summary.viewed';
    }
  }
};
