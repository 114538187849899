import { combineReducers } from 'redux';

import dirty from './dirty';
import mealOptions from './mealOptions';
import nutritionSummaries from './nutritionSummaries';
import servings from './servings';
import subtotals from './subtotals';
import suggestedPairings from './suggestedPairings';

export default combineReducers({
  servings,
  mealOptions,
  nutritionSummaries,
  subtotals,
  suggestedPairings,
  dirty,
});

export const selectServings = state => state.servings;
export const selectMealOptions = state => state.mealOptions;
export const selectSubtotals = state => state.subtotals;
export const selectNutritionSummaries = state => state.nutritionSummaries;
export const selectSuggestedPairings = state => state.suggestedPairings;
export const selectBasketDirty = state => state.dirty;
